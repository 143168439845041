<template>
  <div>
    <!-- text fields -->
    <v-card class="mx-auto pb-7 pt-4 rounded-xl" max-width="600" style="z-index: 1000;">
      <v-card-title class="mx-auto justify-center">
        <i class="fal fa-sign-out mr-2" style="line-height: 0;"></i>You're logged out!
      </v-card-title>
      <p class="text-center">
        <img style="max-height: 200px" src="/img/art/clip-1618.png" />
      </p>
      <p class="text-center">
        <i class="fal fa-spinner fa-spin mr-2" style="line-height: 0;"></i>Redirecting...
      </p>
    </v-card>
    <div class="img-bg"></div>
    <div class="bg-confetti">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 320">
        <path
          fill="rgba(71, 68, 67, 0.03)"
          fill-opacity="1"
          d="M0,192L48,160C96,128,192,64,288,64C384,64,480,128,576,170.7C672,213,768,235,864,229.3C960,224,1056,192,1152,197.3C1248,203,1344,245,1392,266.7L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("logOut");
    setTimeout(() => {
      this.$router.push({ name: "login" });
    }, 2000);
  }
};
</script>

<style></style>
